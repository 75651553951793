import React, { useState, useEffect, useRef } from 'react';
import { Box, Avatar, Typography, Card, CardContent, Grid, Button, Tooltip, TextField, IconButton } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import FacebookIcon from '@mui/icons-material/Facebook';
import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';
import SideNavigationBar from './SideNavigationBar';
import LoadingBar from 'react-top-loading-bar';
import { styled } from '@mui/material/styles';
import { blue } from '@mui/material/colors';

interface PageData {
  pageId: string;
  pageName: string;
  pagePhotoUrl: string;
}

interface DashboardData {
  pages: PageData[];
  instagramBusinessAccounts: any[]; // Assuming you have a type for Instagram accounts
}

const Dashboard = () => {
  const [data, setData] = useState<DashboardData>({ pages: [], instagramBusinessAccounts: [] });
  const [isLoading, setIsLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const loadingBarRef = useRef<any>(null); // Add type to useRef
  const navigate = useNavigate();

  const StyledSearchIcon = styled(SearchIcon)(({ theme }) => ({
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    color: theme.palette.grey[500], // Customize the color as needed
  }));

  const fetchData = async () => {
    if (loadingBarRef.current) {
      loadingBarRef.current.continuousStart();
    }
    try {
      const response = await fetch('/api/dashboard', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 401) {
        navigate('/');
        return;
      }
      
      const result = await response.json();
      console.log(result);
      setData(result);
      if (loadingBarRef.current) {
        loadingBarRef.current.complete();
      }
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsLoading(false);
      if (loadingBarRef.current) {
        loadingBarRef.current.complete();
      }

    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleConnectFacebook = () => {
    window.location.href = '/auth/facebook';
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const filteredPages = data.pages.filter(page => 
    page.pageName.toLowerCase().includes(searchQuery.toLowerCase()) ||
    page.pageId.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Box display="flex">
      <LoadingBar color={blue[500]} ref={loadingBarRef} /> {/* LoadingBar is now correctly typed */}
      <SideNavigationBar accountData={null} error={null}/>
      <Box p={3} flexGrow={1}>
        <Box mb={3}>
          <Typography variant="h4" component="h1" align="left">
            Dashboard
          </Typography>
          <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
            <TextField
              variant="outlined"
              size="small"
              value={searchQuery}
              onChange={handleSearchChange}
              placeholder="Search"
              InputProps={{
                startAdornment: (
                  <StyledSearchIcon />
                ),
              }} 
              sx={{ width: '100%', maxWidth: 400 }}
            />
            <Box display="flex" alignItems="center">
              <Button
                variant="outlined"
                color="primary"
                startIcon={<FacebookIcon style={{ color: '#1877F2' }} />}
                onClick={handleConnectFacebook}
                sx={{ ml: 2 }}
              >
                Add Pages
              </Button>
            </Box>
          </Box>
        </Box>
        {isLoading ? null : (
          <Box>
            {filteredPages.length === 0 ? (
              <Box display="flex" flexDirection="column" alignItems="center" mt={5}>
                <Typography variant="h6" color="textSecondary" gutterBottom>
                  There are no pages connected.
                </Typography>
              </Box>
            ) : (
              <Box>
                <Grid container spacing={2} sx={{ mt: 2 }}>
                  {filteredPages.map(page => (
                    <Grid item key={page.pageId} xs={12} sm={6} md={4} lg={3}>
                      <Link to={`/dashboard/${page.pageId}/automation`} style={{ textDecoration: 'none' }}>
                        <Card sx={{ boxShadow: 1, borderRadius: 2, height: '100%', transition: 'transform 0.2s, box-shadow 0.2s', '&:hover': { transform: 'scale(1.05)', boxShadow: 6 } }}>
                          <CardContent sx={{ transition: 'box-shadow 0.2s', '&:hover': { boxShadow: 6 } }}>
                            <Box display="flex" justifyContent="center" mb={1}>
                              <Avatar
                                src={page.pagePhotoUrl}
                                alt={page.pageName}
                                sx={{ width: 40, height: 40, borderRadius: 0 }}
                              />
                            </Box>
                            <Typography variant="subtitle1" component="h2" align="center">
                              {page.pageName}
                            </Typography>
                            <Box display="flex" alignItems="center" justifyContent="center" mt={1}>
                              <FacebookIcon sx={{ mr: 1, color: '#3b5998' }} />
                              <Typography variant="body2">{page.pageId}</Typography>
                            </Box>
                          </CardContent>
                        </Card>
                      </Link>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Dashboard;
