import {
  Button,
  Typography,
  Box,
  AppBar,
  Toolbar,
  Link,
  Grid,
  Container,
  useMediaQuery,
} from "@mui/material";
import { styled, ThemeProvider, createTheme } from "@mui/material/styles";
import FacebookIcon from "@mui/icons-material/Facebook";
import MessageIcon from "@mui/icons-material/Message";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import SendIcon from "@mui/icons-material/Send";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1976d2",
    },
    secondary: {
      main: "#ff4081",
    },
    text: {
      primary: "#000000",
      secondary: "#757575",
    },
    background: {
      default: "#f5f5f5",
      paper: "#ffffff",
    },
    divider: "#e0e0e0",
    grey: {
      200: "#eeeeee",
    },
  },
  typography: {
    fontFamily: "Roboto, sans-serif",
  },
});

const RootContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "flex-start",
  minHeight: "90vh",
  textAlign: "center",
  padding: "20px",
  backgroundColor: theme.palette.background.default,
  width: "100%",
});

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: "20px",
  width: "250px",
  fontSize: "16px",
  backgroundColor: "#ffffff",
  color: theme.palette.text.primary,
  border: `1px solid ${theme.palette.divider}`,
  "&:hover": {
    backgroundColor: theme.palette.grey[200],
  },
}));

const LoginButton = ({
  platform,
  url,
  Icon,
  iconColor,
}: {
  platform: string;
  url: string;
  Icon: any;
  iconColor: string;
}) => {
  const handleLogin = () => {
    if (typeof window.fbq === 'function') {  // Check if fbq is defined
      window.fbq('track', 'Lead', {
      });
    }
    window.location.href = url;
  };

  return (
    <StyledButton
      variant="contained"
      onClick={handleLogin}
      startIcon={<Icon style={{ color: iconColor, fontSize: "32px" }} />}
    >
      Get started with {platform}
    </StyledButton>
  );
};

const FeatureCard = ({
  icon: Icon,
  title,
  description,
}: {
  icon: any;
  title: string;
  description: string;
}) => (
  <Box sx={{ textAlign: "left", p: 3 }}>
    <Icon sx={{ fontSize: 48, color: theme.palette.primary.main, mb: 2 }} />
    <Typography variant="h6" gutterBottom>
      {title}
    </Typography>
    <Typography variant="body2" color="textSecondary">
      {description}
    </Typography>
  </Box>
);

const Header = () => {
  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <AppBar
      position="sticky"
      color="default"
      elevation={4}
      sx={{ top: 0, backgroundColor: theme.palette.background.paper }}
    >
      <Toolbar>
        <Box
          display="flex"
          alignItems="center"
          justifyContent={isMobile ? "center" : "space-between"}
          width="100%"
        >
          <Box display="flex" alignItems="center" sx={{ mr: isMobile ? 0 : 2 }}>
            <img src="/logo.svg" alt="ChatSuccess AI Logo" height="40" />
          </Box>

          {!isMobile && (
            <Box display="flex" alignItems="center">
              <Button
                variant="text"
                color="primary"
                sx={{
                  cursor: "pointer",
                  marginRight: 2,
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: theme.palette.action.hover,
                  },
                }}
                onClick={() =>
                  window.open("https://m.me/408901325641502", "_blank")
                }
              >
                Support
              </Button>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  marginLeft: 2,
                  textTransform: "none",
                }}
                onClick={() => (window.location.href = "/auth/facebook")}
              >
                Login
              </Button>
            </Box>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

const Footer = () => (
  <Box
    component="footer"
    py={3}
    textAlign="center"
    borderTop={1}
    borderColor="grey.300"
    width="100%"
    bgcolor={theme.palette.background.paper}
  >
    <Typography variant="body2" color="textSecondary">
      &copy; 2024 Chat Success AI. All rights reserved.
    </Typography>
    <Box mt={1}>
      <Link href="/privacy" color="primary" underline="hover">
        Privacy Policy
      </Link>
      {" | "}
      <Link href="/terms-of-services" color="primary" underline="hover">
        Terms of Service
      </Link>
    </Box>
  </Box>
);

const LandingPage = () => {
  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          backgroundColor: theme.palette.background.paper,
          minHeight: "100vh",
        }}
      >
        <Header />
        <RootContainer>
          <Box my={4} textAlign="center" py={5}>
            <Typography
              variant="h5"
              component="p"
              gutterBottom
              color="textSecondary"
            >
              Grow your business and boost sales with tools to optimize Facebook
              and Instagram reach.
            </Typography>
            <Box mt={4} display="flex" justifyContent="center" gap={2}>
              <LoginButton
                platform="Facebook"
                url="/auth/facebook"
                Icon={FacebookIcon}
                iconColor="#1877F2"
              />
            </Box>
          </Box>

          <Container maxWidth="lg">
            <Typography
              variant="h4"
              component="h2"
              gutterBottom
              color="primary"
              align="center"
            >
              Features
            </Typography>
            <Grid container spacing={4} justifyContent="center">
              <Grid item xs={12} sm={6} md={4}>
                <FeatureCard
                  icon={MessageIcon}
                  title="Reply to Inbox Messages"
                  description="Create a welcome message and quick reply buttons to instantly engage with your customers."
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FeatureCard
                  icon={ThumbUpIcon}
                  title="Reply to Comments"
                  description="Automatically respond to comments on Facebook and Instagram, saving you time and effort."
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FeatureCard
                  icon={SendIcon}
                  title="Broadcast Messages"
                  description="Message all users who recently interacted with your page at once, keeping them engaged and informed."
                />
              </Grid>
            </Grid>
          </Container>
        </RootContainer>
        <Footer />
      </Box>
    </ThemeProvider>
  );
};

export default LandingPage;
