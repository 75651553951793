import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Switch,
  IconButton,
  Tooltip,
  Grid,
  InputAdornment,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CreateAutomationDialog from "./CreationAutomationDialog";
import AddIcon from "@mui/icons-material/Add";

// Define your CommentAutomationType interface if you haven't already.  Make sure it matches your API response.
interface CommentAutomationType {
  id: string;
  selectedPosts: string[];
  name: string;
  postSelection: "selective" | "all";
  keywords: string[];
  message: string;
  numRuns: number;
  lastRunTime: any; // Assuming Firebase Timestamp
  numLinkClicks: number;
  createdTime: any;// Assuming Firebase Timestamp
  isActive: boolean;
}


interface Props {
  accountId: string | null;
}

const CommentAutomation: React.FC<Props> = ({ accountId }) => {
  const [commentAutomations, setCommentAutomations] = useState<
    CommentAutomationType[]
  >([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedAutomationId, setSelectedAutomationId] = useState<
    string | null
  >(null);
  const [showCreateDialog, setShowCreateDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchAutomations = async () => {
      if (!accountId) {
        setIsLoading(false);
        return;
      }

      try {
        const response = await fetch(
          `/api/${accountId}/comment-automations`
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setCommentAutomations(data);
      } catch (error: any) {
        setError(error.message);
        console.error("Error fetching automations:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchAutomations();
  }, [accountId]);


  const handleActivateToggle = async (id: string, accountId: string | null) => {  // Add accountId
    try {
      const updatedAutomations = commentAutomations.map((automation) =>
        automation.id === id
          ? { ...automation, isActive: !automation.isActive }
          : automation
      );
  
      setCommentAutomations(updatedAutomations); // Optimistic UI update
  
      const automationToUpdate = updatedAutomations.find(a => a.id === id);
      if (!automationToUpdate) {
        throw new Error("Automation not found");
      }
      console.log('update');
      const response = await fetch(`/api/${accountId}/comment-automation/${id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ isActive: automationToUpdate.isActive }),
      });
  
    } catch (error: any) {
      console.error("Error updating automation:", error);
      alert(`Error updating automation: ${error.message}`);
    }
  };
      
  const handleRemoveAutomation = async (id: string, accountId: string | null) => { // Add accountId
    try {
      // Optimistic UI update (remove from local state first)
      setCommentAutomations((prevAutomations) =>
        prevAutomations.filter((automation) => automation.id !== id)
      );
  
      const response = await fetch(`/api/${accountId}/comment-automation/${id}`, { // Include accountId
        method: 'DELETE',
      });
  
      if (!response.ok) {
        console.error('Error deleting automation:', response.statusText);
        alert("Failed to delete automation. Please try again.");
      }
  
  
    } catch (error) {
      console.error('Error deleting automation:', error);
      alert("Failed to delete automation. Please try again.");
    }
  };

  const handleCreateAutomation = async (
    automationName: string,
    postSelection: "selective" | "all",
    selectedPosts: string[],
    keywords: string[],
    message: string
  ) => {
    if (!accountId) {
      console.error("Account ID is missing.");
      return;
    }

    try {
      const response = await fetch(
        `/api/${accountId}/comment-automation`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: automationName,
            postSelection,
            selectedPosts,
            keywords,
            message,
          }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        const errorMessage = errorData.message || "Failed to create automation.";
        throw new Error(errorMessage);
      }

      const data = await response.json();
      console.log("Automation created successfully:", data);
      handleCloseCreateDialog();
    } catch (error: any) {
      console.error("Error creating automation:", error);
      alert(`Error creating automation: ${error.message}`);
    }
  };

  const handleOpenCreateDialog = () => {
    setShowCreateDialog(true);
  };

  const handleCloseCreateDialog = () => {
    setShowCreateDialog(false);
  };

  const handleViewAutomation = (id: string) => {
    setSelectedAutomationId(id);
  };

  const handleUpdateAutomation = (updatedAutomation: CommentAutomationType) => {
    // In a real application, you'd send the updatedAutomation to your API
    setCommentAutomations((prevAutomations) =>
      prevAutomations.map((automation) =>
        automation.id === updatedAutomation.id
          ? updatedAutomation
          : automation
      )
    );
    setSelectedAutomationId(null);
  };

  const filteredAutomations = commentAutomations.filter((automation) =>
    automation.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Box sx={{ p: 2 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          mb: 2,
        }}
      >
        <Typography variant="h5" gutterBottom>
          Automation
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Button
          variant="contained"
          onClick={handleOpenCreateDialog}
          startIcon={<AddIcon />}
        >
          Add Automation
        </Button>
        <CreateAutomationDialog
          open={showCreateDialog}
          accountId={accountId}
          onPublish={handleCreateAutomation}
          onClose={handleCloseCreateDialog}
        />
      </Box>
      {isLoading ? (
        <Typography variant="body1">Loading automations...</Typography>
      ) : error ? (
        <Typography variant="body1" color="error">
          Error loading automations: {error}
        </Typography>
      ) : (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Created Time</TableCell>
              <TableCell>Last Run Time</TableCell>
              <TableCell>Number of Runs</TableCell>
              <TableCell>Number of Link Clicks</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredAutomations.length > 0 ? (
              filteredAutomations.map((automation) => (
                <TableRow key={automation.id}>
                  <TableCell>{automation.name}</TableCell>
                  <TableCell>
                  <Switch
                    checked={automation.isActive}
                    onChange={() => handleActivateToggle(automation.id, accountId)}
                  />
                </TableCell>
                  <TableCell>
                    {'-'}
                  </TableCell>
                  <TableCell>
                    {automation.lastRunTime
                      ? automation.lastRunTime
                      : "-"}
                  </TableCell>
                  <TableCell>{automation.numRuns}</TableCell>
                  <TableCell>{automation.numLinkClicks}</TableCell>
                  <TableCell align="right">
                    <Grid container spacing={1} alignItems="center">
                      <Grid item>
                        <Tooltip title="View/Edit">
                          <IconButton
                            onClick={() =>
                              handleViewAutomation(automation.id)
                            }
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                      <Grid item>
                        <Tooltip title="Delete">
                          <IconButton
                            onClick={() =>
                              handleRemoveAutomation(automation.id, accountId)
                            }
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  <Typography variant="body1">
                    There are no automations yet.
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      )}
      {/* <ViewAutomationDialog
        open={selectedAutomationId !== null}
        onClose={() => setSelectedAutomationId(null)}
        automation={
          commentAutomations.find(
            (automation) => automation.id === selectedAutomationId
          ) || null
        }
        onUpdate={handleUpdateAutomation}
      /> */}
    </Box>
  );
};

export default CommentAutomation;
