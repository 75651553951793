import { Container, Typography, Box } from '@mui/material';

const PrivacyPolicy = () => {
  return (
    <Container maxWidth="md" style={{ padding: '20px' }}>
      <Box my={4}>
        <Typography variant="h3" component="h1" gutterBottom>
          Privacy Policy
        </Typography>
        <Typography variant="body1" paragraph>
          This page is used to inform website visitors regarding our policies with the collection, use, and disclosure of data if anyone decided to use our Service.
        </Typography>
        <Typography variant="body1" paragraph>
          If you choose to use our Service, then you agree to the collection and use of information in relation to this policy. The data that we collect is used for providing and improving the Service. We will not use or share your data with anyone except as described in this Privacy Policy.
        </Typography>
        <Typography variant="body1" paragraph>
          The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which is accessible at ChatSuccess AI unless otherwise defined in this Privacy Policy.
        </Typography>
        <Typography variant="h4" component="h2" gutterBottom>
          Data Collection and Use
        </Typography>
        <Typography variant="body1" paragraph>
          In order to function, the app will collect your name, email address, your Facebook Page list and its data which include public data such as comments, posts, and private messages. The information that we request will be retained by us and used as described in this privacy policy.
        </Typography>
        <Typography variant="body1" paragraph>
          Your data is stored on our server as long as your subscription is active. When an active subscription is no longer available, we have the right to remove your data automatically from our database after 60 days. In case you want to stop using our service and remove all data completely, please let us know. We will fulfill your request within 24 hours.
        </Typography>
        <Typography variant="body1" paragraph>
          For a better experience and statistical purposes, the app does use third-party services that may collect information used to identify you. These third-party services may collect information such as your device Internet Protocol (“IP”) address, device name, operating system version, the time and date of your use of our app, and other statistics.
        </Typography>
        <Typography variant="h4" component="h2" gutterBottom>
          Security
        </Typography>
        <Typography variant="body1" paragraph>
          We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.
        </Typography>
        <Typography variant="h4" component="h2" gutterBottom>
          Changes to This Privacy Policy
        </Typography>
        <Typography variant="body1" paragraph>
          We may update our Privacy Policy from time to time. Thus, we advise you to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page. These changes are effective immediately after they are posted on this page.
        </Typography>
        <Typography variant="h4" component="h2" gutterBottom>
          Contact Us
        </Typography>
        <Typography variant="body1" paragraph>
          If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at chatsuccess.ai@gmail.com.
        </Typography>
      </Box>
    </Container>
  );
};

export default PrivacyPolicy;