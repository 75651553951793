import React from 'react';
import { Box, Container } from '@mui/material';
import { Outlet } from 'react-router-dom';
import SideNavigationBar from '../components/SideNavigationBar'; 

interface AccountData {
  id: string;
  name: string;
  photoUrl: string;
  accountType: string;
}

interface LayoutProps {
  data: AccountData | null;
  isLoading: boolean;
  error: string | null;
}

const Layout: React.FC<LayoutProps> = ({ data, error }) => {
  return (
    <Box display="flex">
      <SideNavigationBar accountData={data} error={error} />
      <Container sx={{ ml: 1, p: 0 }}>
        <Box display="flex" justifyContent="space-between" mt={1} mb={1}>
          <Box width="100%" sx={{ p: 0 }}>
            <Outlet />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Layout;
