import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  TextField,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControlLabel,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Checkbox,
  FormControl,
  RadioGroup,
  Radio,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Chip,
  Pagination,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

interface Props {
  accountId: string | null;
  open: boolean;
  onClose: () => void;
  onPublish: (
    automationName: string,
    postSelection: "selective" | "all",
    selectedPosts: string[],
    keywords: string[],
    message: string
  ) => void;
}

const CreateAutomationDialog: React.FC<Props> = ({
  accountId,
  open,
  onClose,
  onPublish,
}) => {
  const [automationName, setAutomationName] = useState(
    "Give an automation name"
  );
  const [postSelection, setPostSelection] = useState<"selective" | "all">(
    "selective"
  );
  const [selectedPosts, setSelectedPosts] = useState<string[]>([]);
  const [posts, setPosts] = useState<any[]>([]);
  const [message, setMessage] = useState("");
  const [keywords, setKeywords] = useState<string[]>([]);
  const [newKeyword, setNewKeyword] = useState("");
  const postsPerPage = 3;
  const [currentPage, setCurrentPage] = useState(1);

  const handleKeywordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewKeyword(event.target.value);
  };

  const handleAddKeyword = () => {
    const trimmedKeyword = newKeyword.trim();
    if (trimmedKeyword !== "") {
      setKeywords([...keywords, trimmedKeyword]);
      setNewKeyword("");
    }
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentPage(value);
  };

  const handleDeleteKeyword = (keywordToDelete: string) => {
    setKeywords(keywords.filter((keyword) => keyword !== keywordToDelete));
  };

  useEffect(() => {
    const fetchPosts = async () => {
      if (!accountId) return;

      try {
        const response = await fetch(`/api/page/${accountId}/posts`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setPosts(data);
      } catch (error) {
        console.error("Error fetching posts:", error);
      }
    };

    fetchPosts();
  }, [accountId]);
  const handlePostSelectionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPostSelection(event.target.value as "selective" | "all");
    setSelectedPosts([]);
  };

  const handlePostSelect = (postId: string) => {
    setSelectedPosts((prevSelected) =>
      prevSelected.includes(postId)
        ? prevSelected.filter((id) => id !== postId)
        : [...prevSelected, postId]
    );
  };

  const handleSave = () => {
    onPublish(automationName, postSelection, selectedPosts, keywords, message);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle>Create Comment Automation</DialogTitle>
      <DialogContent>
        <Paper sx={{ p: 2 }}>
          <TextField
            label="Automation Name"
            variant="outlined"
            value={automationName}
            onChange={(e) => setAutomationName(e.target.value)}
            sx={{ mb: 2 }}
          />

          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">Post</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <FormControl component="fieldset">
                <RadioGroup
                  value={postSelection}
                  onChange={handlePostSelectionChange}
                >
                  <FormControlLabel
                    value="all"
                    control={<Radio />}
                    label="Apply for all posts"
                  />
                  <FormControlLabel
                    value="selective"
                    control={<Radio />}
                    label="Apply for specific posts"
                  />
                </RadioGroup>
              </FormControl>
              {postSelection === "selective" && posts.length > 0 && (
                <Box sx={{ mt: 2 }}>
                  <Grid container spacing={2}>
                    {posts
                      .slice(
                        (currentPage - 1) * postsPerPage,
                        currentPage * postsPerPage
                      )
                      .map((post) => (
                        <Grid item xs={12} sm={6} md={4} key={post.id}>
                          <Card
                            sx={{
                              height: "100%",
                              display: "flex",
                              flexDirection: "column",
                              boxShadow: 1,
                              borderRadius: 2,
                              transition: "transform 0.2s, box-shadow 0.2s",
                              "&:hover": {
                                transform: "scale(1.05)",
                                boxShadow: 6,
                              },
                            }}
                          >
                            {post.full_picture && (
                              <CardMedia
                                component="img"
                                height="250"
                                image={post.full_picture}
                                alt={
                                  post.message && post.message.length > 75
                                    ? `${post.message.substring(0, 75)}...`
                                    : post.message
                                }
                              />
                            )}
                            <CardContent
                              sx={{
                                flexGrow: 1,
                                position: "relative",
                              }}
                            >
                              <Typography
                                gutterBottom
                                variant="subtitle1"
                                component="div"
                                onClick={() =>
                                  window.open(post.permalink_url, "_blank")
                                }
                                sx={{ cursor: "pointer" }}
                              >
                                {post.message && post.message.length > 75
                                  ? `${post.message.substring(0, 75)}...`
                                  : post.message}
                              </Typography>
                              <Box
                                sx={{
                                  position: "absolute",
                                  bottom: 8,
                                  right: 8,
                                }}
                              >
                                <Checkbox
                                  checked={selectedPosts.includes(post.id)}
                                  onChange={() => handlePostSelect(post.id)}
                                />
                              </Box>
                            </CardContent>
                          </Card>
                        </Grid>
                      ))}
                  </Grid>
                  <Box
                    sx={{
                      mt: 2,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Pagination
                      count={Math.ceil(posts.length / postsPerPage)}
                      page={currentPage}
                      onChange={handlePageChange}
                      color="primary"
                    />
                  </Box>
                </Box>
              )}
            </AccordionDetails>
          </Accordion>

          <Accordion defaultExpanded sx={{ mt: 2 }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">Trigger</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TextField
                label="Add Keywords (Press Enter to add)"
                variant="standard"
                fullWidth
                value={newKeyword}
                onChange={handleKeywordChange}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleAddKeyword();
                  }
                }}
                sx={{ mb: 2 }}
              />
              <Box sx={{ mt: 1 }}>
                {keywords.map((keyword) => (
                  <Chip
                    key={keyword}
                    label={keyword}
                    onDelete={() => handleDeleteKeyword(keyword)}
                    sx={{ mr: 1, mb: 1 }}
                  />
                ))}
              </Box>
            </AccordionDetails>
          </Accordion>

          <Accordion defaultExpanded sx={{ mt: 2 }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">Message</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TextField
                label="Enter your message here"
                variant="outlined"
                fullWidth
                multiline
                rows={4}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </AccordionDetails>
          </Accordion>
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSave} variant="contained">
          Publish
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateAutomationDialog;
